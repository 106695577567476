import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link } from "react-router-dom";
import PageHeaderDesktop from './PageHeaderDesktop';
import PageHeaderMobile from './PageHeaderMobile';
import Footer from './Footer';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import folder from './folder.json';
let localhostName = require('../' + folder._ + '/config.json');

export default class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            userInfo: this.props.userInfo
        };
    }
    componentDidMount() {
        $("strong").each(function () {
            let tt = $(this).text().split(" - ")[1];
            $(this).html(tt);
        });
        axios.post(localhostName.host + '/api/pagetrackers/add', { type: "page", "page": window.location.pathname })
            .then(res => {
            })
            .catch((error) => {
            });
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div className={"items_page " + (isMobile ? "mobile" : "")}>
                {isMobile ?
                    <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                    :
                    <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
                }
                <div className="container technique_page">
                    <div className="technique_overview">
                        <div>
                            <p><strong>PRIVACY NOTICE</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Last updated August 18, 2022</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>This privacy notice for Golftus Ltd ('<strong>Company</strong>', '<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>',), describes how and why we might collect, store, use, and/or share ('<strong>process</strong>') your information when you use our services ('<strong>Services</strong>'), such as when you:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Visit our website at scieart.com, or any website of ours that links to this privacy notice</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Engage with us in other related ways, including any sales, marketing, or events</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Questions or concerns? </strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@scieart.com.</span></p>
                            <p><strong>SUMMARY OF KEY POINTS</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click </strong></i></span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}><i><strong>here </strong></i></span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>to go directly to our table of contents.</strong></i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>What personal information do we process? </strong>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Golftus Ltd and the Services, the choices you make, and the products and features you use. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Do we process any sensitive personal information? </strong>We do not process sensitive personal information. <strong>Do we receive any information from third parties? </strong>We may receive information from public databases,</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>marketing partners, social media platforms, and other outside sources. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>How do we process your information? </strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>In what situations and with which parties do we share personal information? </strong>We may share information in specific situations and with specific third parties. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>How do we keep your information safe? </strong>We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>What are your rights? </strong>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>How do you exercise your rights? </strong>The easiest way to exercise your rights is by filling out our data masterject request form available here: scieart.com/profile, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Want to learn more about what Golftus Ltd does with any information we collect? Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to review the notice in full.</span></p>
                            <p><strong>TABLE OF CONTENTS</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>1. WHAT INFORMATION DO WE COLLECT?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>2. HOW DO WE PROCESS YOUR INFORMATION?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION? </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span><br /><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>9. WHAT ARE YOUR PRIVACY RIGHTS?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>12. DO WE MAKE UPDATES TO THIS NOTICE?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></p>
                            <p><strong>1. WHAT INFORMATION DO WE COLLECT? Personal information you disclose to us</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We collect personal information that you provide to us.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Personal Information Provided by You. </strong>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>names</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>phone numbers email addresses</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>mailing addresses</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>job titles</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>usernames</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>passwords</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>contact preferences</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>contact or authentication data billing addresses</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>debit/credit card numbers</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Sensitive Information. </strong>We do not process sensitive information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Payment Data. </strong>We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Paypal. You may find their privacy notice link(s)</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>here: </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>https://www.paypal.com/myaccount/privacy/privacyhub</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></p>
                            <p><strong>Information automatically collected</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Like many businesses, we also collect information through cookies and similar technologies. You can find out more about this in our Cookie Notice: scieart.com/cookie-policy.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>The information we collect includes:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i>Log and Usage Data. </i>Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i>Device Data. </i>We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i>Location Data. </i>We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</span></p>
                            <p><strong>Information collected from other sources</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We may collect limited data from public databases, marketing partners, and other outside sources.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.</span></p>
                            <p><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To facilitate account creation and authentication and otherwise manage user accounts. </strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To deliver and facilitate delivery of services to the user. </strong>We may process your information to provide you with the requested service.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To respond to user inquiries/offer support to users. </strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To fulfil and manage your orders. </strong>We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To request feedback. </strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To identify usage trends. </strong>We may process information about how you use our Services to better understand how they are being used so we can improve them.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To determine the effectiveness of our marketing and promotional campaigns. </strong>We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>To save or protect an individual's vital interest. </strong>We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></p>
                            <p><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>If you are located in the EU or UK, this section applies to you.</strong></i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Consent. </strong>We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Performance of a Contract. </strong>We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Legitimate Interests. </strong>We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Analyse how our services are used so we can improve them to engage and retain users Support our marketing activities</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Understand how our users use our products and services so we can improve user experience</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Legal Obligations. </strong>We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Vital Interests. </strong>We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>In legal terms, we are generally the 'data controller' under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This privacy notice does not apply to the personal information we process as</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>a 'data processor' on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the 'data controller' responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>If you are located in Canada, this section applies to you.</strong></i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time. Click </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>here </span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>to learn more.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>For investigations and fraud detection and prevention</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>For business transactions provided certain conditions are met</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>For identifying injured, ill, or deceased persons and communicating with next of kin</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If disclosure is required to comply with a masterpoena, warrant, court order, or rules of the court relating to the production of records</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If the collection is solely for journalistic, artistic, or literary purposes</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If the information is publicly available and is specified by the regulations</span></p>
                            <p><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We may share information in specific situations described in this section and/or with the following third parties.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may need to share your personal information in the following situations:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Business Transfers. </strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>When we use Google Maps Platform APIs. </strong>We may share your information with certain Google Maps Platform APIs (e.g. Google Maps API, Places API). To find out more about Google’s Privacy Policy, please refer to this </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>link</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>. We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Business Partners. </strong>We may share your information with our business partners to offer you certain products, services, or promotions.</span></p>
                            <p><strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We may use cookies and other tracking technologies to collect and store your information.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice: scieart.com/cookie-policy.</span></p>
                            <p><strong>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We may transfer, store, and process your information in countries other than your own.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Our servers are located in the United Kingdom. If you are accessing our Services from outside the United Kingdom, please be aware that your information may be transferred to, stored, and processed by us in our</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>facilities and by those third parties with whom we may share your personal information (see 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?' above), in and other countries.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</span></p>
                            <p><strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination of the user's account.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span></p>
                            <p><strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>We aim to protect your personal information through a system of organisational and technical security measures.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span></p>
                            <p><strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>information. You can make such a request by contacting us by using the contact details provided in the section '</span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>' below.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We will consider and act upon any request in accordance with applicable data protection laws.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are located in Switzerland, the contact details for the data protection authorities are available here: </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>https://www.edoeb.admin.ch/edoeb/en/home.html</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Withdrawing your consent: </strong>If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section '</span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>' below.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Opting out of marketing and promotional communications: </strong>You can unmasterscribe from our marketing and promotional communications at any time by clicking on the unmasterscribe link in the emails that we send, or by contacting us using the details provided in the section '</span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>HOW CAN YOU CONTACT US ABOUT THIS</span><br /><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>NOTICE?</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</span></p>
                            <p><strong>Account Information</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you would at any time like to review or change the information in your account or terminate your account, you can:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Log in to your account settings and update your user account.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Cookies and similar technologies: </strong>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit </span><span style={{ color: 'rgb(18.820000%,18.820000%,94.510000%)' }}>http://www.aboutads.info/choices/</span><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>. For further information, please see our Cookie Notice: scieart.com/cookie-policy.</span></p>
                            <p><strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></p>
                            <p><strong>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please mastermit your request in writing to us using the contact information provided below.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</span></p>
                            <p><strong>CCPA Privacy Notice</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>The California Code of Regulations defines a 'resident' as:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>(1) every individual who is in the State of California for other than a temporary or transitory purpose and (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>All other individuals are defined as 'non-residents'.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If this definition of 'resident' applies to you, we must adhere to certain rights and obligations regarding your personal information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>What categories of personal information do we collect?</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We have collected the following categories of personal information in the past twelve (12) months:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Category Examples Collected</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>A. Identifiers</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Contact details, such as real name, alias, postal address, telephone or</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>B. Personal information categories listed in the California Customer Records statute</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Name, contact information, education, employment, employment history, and financial information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Gender and date of birth</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>C. Protected classification characteristics under California or federal law</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>D. Commercial information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Transaction information, purchase history, financial details, and payment information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>E. Biometric information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Fingerprints and voiceprints</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>NO</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>F. Internet or other similar network activity</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>NO</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>G. Geolocation data</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Device location</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>H. Audio, electronic, visual, thermal, olfactory, or similar information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Images and audio, video or call recordings created in connection with our business activities</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>NO</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>I. Professional or employment-related information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>J. Education Information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Student records and directory information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>NO</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>K. Inferences drawn from other personal information</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>YES</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Receiving help through our customer support channels;</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Participation in customer surveys or contests; and</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Facilitation in the delivery of our Services and to respond to your inquiries.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>How do we use and share your personal information?</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>More information about our data collection and sharing practices can be found in this privacy notice and our Cookie Notice: scieart.com/cookie-policy.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You may contact us by email at policy@scieart.com, or by referring to the contact details at the bottom of this document.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are using an authorised agent to exercise your right to opt out we may deny a request if</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the authorised agent does not mastermit proof that they have been validly authorised to act on your behalf.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Will your information be shared with anyone else?</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Golftus Ltd has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Golftus Ltd will not sell personal information in the future belonging to website visitors, users, and other consumers.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><strong>Your rights with respect to your personal data</strong></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Right to request deletion of the data — Request to delete</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, masterject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Right to be informed — Request to know</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Depending on the circumstances, you have a right to know: whether we collect and use your personal information;</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the categories of personal information that we collect;</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the purposes for which the collected personal information is used;</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>whether we sell your personal information to third parties;</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the categories of personal information that we sold or disclosed for a business purpose;</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>the business or commercial purpose for collecting or selling personal information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de- identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights We will not discriminate against you if you exercise your privacy rights. Verification process</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you mastermit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud- prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Other privacy rights</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You may object to the processing of your personal information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You can designate an authorised agent to make a request under the CCPA on your behalf. We may deny a request from an authorised agent that does not mastermit proof that they have been validly authorised to act on your behalf in accordance with the CCPA.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>You may request to opt out from future selling of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15)</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>days from the date of the request mastermission.</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>To exercise these rights, you can contact us by email at policy@scieart.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</span></p>
                            <p><strong>12. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}><i><strong>In Short: </strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>We may update this privacy notice from time to time. The updated version will be indicated by an</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></p>
                            <p><strong>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you have questions or comments about this notice, you may email us at __________ or by post to:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Golftus Ltd</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>If you are a resident in the United Kingdom, the 'data controller' of your personal information is Golftus Ltd. Golftus Ltd has appointed EasyDiscipline  to be its representative in the UK. You can contact them directly regarding the processing of your information by Golftus Ltd, by email at info@scieart.com, by</span><br /><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>visiting scieart.com, or by post to:</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>124 City Road</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>London EC1V 2NX</span></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>United Kingdom</span></p>
                            <p><strong>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></p>
                            <p><span style={{ color: 'rgb(34.900000%,34.900000%,34.900000%)' }}>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: scieart.com/profile.</span></p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}