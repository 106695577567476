import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Link, Routes, BrowserRouter, Navigate } from "react-router-dom";
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";


import RegisterNon from '../components/RegisterNon.js';
import JoinPage from './JoinPage.js';
import JoinPageMobile from './JoinPageMobile.js';
import ListPageFilter from './ListPageFilter.js';
import Terms from './Terms.js';
import Privacy from './Privacy.js';
import Contact from './Contact.js';
import MembershipPage from './MembershipPage.js';
import AboutUs from './AboutUs.js';
import RegisterTest from './RegisterDataTest.js';
import Cookies from './Cookies.js';
import OrderFinished from './OrderFinished.js';
import RecoverPass from './RecoverPass.js';
import MembershipFAQ from './MembershipFAQ.js';
import SingleModal from './SingleModal.js';
import LoginModal from './LoginModal.js';
import MasterList from './Masters/ListPage.js';
import MasterListMobile from './Masters/ListPageMobile.js';
import MobileStart from './MobileStart.js';
import ProfilePage from '../ArtWar/Profile/ProfilePage';
import PagetrackerPage from './PagetrackerPage.js';
import CitiesList from "./Cities/CitiesList.js";
import $ from 'jquery';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import "../Art.css";
import "../App.css";
import "./ad_page.css";
import '../Items.css';
import '../Items_Mobile.css';
import "./Extra.css";
import "./Cities/Cities.css";
import folder from './folder.json';
import ("../" + folder._ + "/Extra.css");
let localhostName = require('../' + folder._ + '/config.json');
let content = (getCookie("content") || null);
const website_info = require(`../${folder._}/Data/website_info.json`);
let no_content_page = website_info.no_content_page;
if(no_content_page){
  content = no_content_page;
};
let lang = getCookie("lang");
if (website_info.no_lang) {
  setCookie("lang", website_info.no_lang)
} else {
  if (!lang) {
    axios.get('https://geolocation-db.com/json/')
      .then(res => {
        if (res.data && ["DE", "CH", "AT"].includes(res.data.country_code)) {
          setCookie("lang", "DE", 365);
          window.location.reload();
        } else {
          setCookie("lang", "EN", 365);
        }
        this.setState({ user_geo: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export default class AppDesktop extends Component {
  constructor() {
    super();
    this.state = {
      fullname: null,
      loggedIn: null,
      users: [{}],
      user: { username: "", user_id: "", fullname: "" },
      register_email: "",
      ip_country: "",
      surf_points: 0,
      customer_data: {},
      items_list: [],
      block: false,
      logged: false,
      basic_payment: false,
      lang: "EN",
      visit_tracker: {
        items: [],
        tabs: [],
        filter: [],
        pages: []
      },
    };
    this.block = this.block.bind(this);
    this.refresh = this.refresh.bind(this);
    this.checkLogged = this.checkLogged.bind(this);
  }
  changeurl() {
    console.log("app updated");
  }
  updateUser(userObject) {
    this.setState(userObject)
  }
  checkLogged() {
    let tracker_stop = getCookie("tracker_stop");
    let tracker_stop_daily = getCookie("tracker_stop_daily");
    let block = this.block;
    axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
      .then(res => {
        if (res.data.status) {
          console.log(res.data.user);
          this.setState({ user: res.data.user, logged: true, basic_payment: res.data.user.basic_payment });
        } else {
          this.setState({ logged: false, user: {} });
          if (tracker_stop === "true" && tracker_stop_daily === "true") {
            block();
          };
        }
      })
      .catch(function (error) {
        console.log(error);
        if (tracker_stop === "true" && tracker_stop_daily === "true") {
          block();
        };
      });
  }
  block() {
    this.setState({ block: true });
  }
  componentDidMount() {
    this.checkLogged();
    window.data_url = localhostName.data;
    axios.post(localhostName.host + '/api/pagetrackers/addvisit', { referrer: window.location.href, referrer_origin: document.referrer, type: "visit" })
      .then(res => {
      })
      .catch((error) => {
      });
    let hash = window.location.hash;
    if (hash == "#betaversion") {
      $('head').append('<link rel="stylesheet" href="/beta_version.css" type="text/css" />');
    };
    let visit_tracker = getCookie("visit_tracker");
    if (visit_tracker) {
      this.setState({ visit_tracker: JSON.parse(visit_tracker) });
    };
  }
  refresh() {
    console.log("dello");
    this.checkLogged();
  }
  visit_tracker() {
    let visit_tracker = this.state.visit_tracker;
    let types = { items: "item", tabs: "tab", filter: "filter", pages: "page" };
    let type = "pages";
    let value = window.location.href;
    if (!(visit_tracker[type].includes(value))) {
      visit_tracker[type].push(value);
      this.setState({ visit_tracker: visit_tracker });
      setCookie("visit_tracker", JSON.stringify(visit_tracker), 365);
    };
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter >
          <Routes>
            <Route path="/join" element={isMobile ? <JoinPageMobile blocked={this.state.block} /> : <JoinPage blocked={this.state.block} />} />
            <Route exact path="/master/:name" key={this.state.block + this.state.logged + this.state.user} element={isMobile ? <MasterListMobile key={this.state.logged + this.state.user} block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} logged={this.state.logged} user={this.state.user} refresh={this.refresh} /> : <MasterList key={this.state.logged + this.state.user.user_data} block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} logged={this.state.logged} user={this.state.user} refresh={this.refresh} />}></Route>
            <Route path="/" key={this.state.block} element={isMobile ? (content && content !== "/" ? <Navigate to={content} /> : <MobileStart />) : (content && content !== "/" ? <Navigate to={content} /> : <MobileStart block={this.block} blocked={this.state.block} basic_payment={this.state.basic_payment} />)}></Route>
            <Route path="/filter" element={(this.state.surf_points < 6 ? <ListPageFilter /> : <JoinPage />)}></Route>
            <Route path="/register" element={<RegisterNon />}></Route>
            <Route path="/start" element={<MobileStart logged={this.state.logged} user={this.state.user} />}></Route>
            <Route path="/registertest" element={<RegisterTest />}></Route>
            <Route path="/membership" key={this.state.logged} element={<MembershipPage logged={this.state.logged} user={this.state.user} />}></Route>
            <Route path="/aboutus" key={this.state.logged} element={<AboutUs logged={this.state.logged} user={this.state.user} />}></Route>
            <Route path="/profile" key={this.state.logged} element={<ProfilePage logged={this.state.logged} basic_payment={this.state.basic_payment} user={this.state.user} />}></Route>
            <Route path="/contact" key={this.state.logged} element={<Contact logged={this.state.logged} />}></Route>
            <Route path="/passwordreset" element={<RecoverPass />}></Route>
            <Route path="/terms-and-conditions" key={this.state.logged} element={<Terms logged={this.state.logged} />}></Route>
            <Route path="/privacy-policy" key={this.state.logged} element={<Privacy logged={this.state.logged} />}></Route>
            <Route path="/uhgfx78t" element={<PagetrackerPage />}></Route>
            <Route path="/order" key={this.state.logged} element={<OrderFinished logged={this.state.logged} />}></Route>
          </Routes>
          <Cookies />
        </BrowserRouter>
        <SingleModal id={"MembershipFAQ_modal"} child_id="MembershipFAQ" >
          <MembershipFAQ />
        </SingleModal >
        <SingleModal id={"Login_modal"} child_id="LoginModal" className="modal_Login">
          <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
        </SingleModal >
      </div>
    )
  }
}


