import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';
import RangeSlider from '../UI/RangeSlider';
let OwnFilters = {}

export default class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OwnFilters: {},
        };
        this.onChange = this.onChange.bind(this);
        this.changeState = this.changeState.bind(this);
    }
    componentDidMount() {
        import(`../Filters/CountriesFilter.js`).then(data => {
            let OwnFilters = this.state.OwnFilters;
            OwnFilters.CountriesFilter = data.default;
            this.setState({ OwnFilters });
        });
    }
    onChange(e) {
        this.props.setState({ [e.target.id]: e.target.value });
    }
    changeState(state, value) {
        this.props.setState({ [state]: value });
    }
    update_filter(value, mustlog) {
        if (mustlog) {
            this.props.notLoggedModal("filter");
            return;
        };
        let filter = this.props.state.filter;
        let index = filter.findIndex((f) => f === value);
        if (index < 0) {
            filter.push(value);
        } else {
            filter = filter.filter(e => e !== value);
        };
        this.props.setState({ filter: filter });
    }
    renderFilter() {
        let filter_ = (this.props.filter_info || []);
        return filter_.map(f => {
            if (f.type !== "own_filter") {
                return (
                    <div className="masterbox">
                        <div className="navbar_subtitle">
                            {f.title}
                            <i className="nav_line"></i>
                        </div>
                        <div className={"navbar_row " + f.classN}>
                            {f.filters.map(filter => {
                                return (
                                    <div className={this.props.state.filter.includes(filter.name) ? "selected" : ""} onClick={() => { this.update_filter(filter.name) }}><img className="image_icon" src={"/icons/" + filter.icon} />{filter.title}</div>
                                )
                            })}
                        </div>
                    </div>
                )
            } else if (f.type === "own_filter" && this.state.OwnFilters[f.name]) {
                let Tag = this.state.OwnFilters[f.name];
                return (
                    < Tag key={this.props.state} state={this.props.state} onChange={this.onChange} changeState={this.changeState} />
                )
            }
        })
    }
    render() {
        return (
            <div className="filter_navbox">
                <div className="navbar_mastertitle main_title">
                    {"Filters"}
                    <i className="nav_line"></i>
                </div>
                <div className="box" onClick={(e) => { this.props.tracker("filter", e.target.innerText); }}>
                    {this.renderFilter()}
                    <div className="results_button" onClick={() => { this.props.changeState("show_filters", false) }}>
                        Show results
                    </div>
                </div>
                <Tooltip id="filter" className="filter_tooltip" />
            </div>
        )
    }
}    