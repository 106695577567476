import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import ModalBoot from '../components/Modal.js';
import LoginModal from './LoginModal.js';
import RegisterData from './RegisterData.js';
import MembershipFAQ from './MembershipFAQ.js';
import MembershipTC from './MembershipTC.js';
import { setCookie, getCookie, delete_cookie } from '../functions/functions.js';
import $ from 'jquery';
import folder from './folder.json';
const page_info_all = require('../' + folder._ + '/Data/join_page.json');
const contentAll = require('../' + folder._ + '/Data/content.json');
const contentAll_coming = require('../' + folder._ + '/Data/content_coming.json');
let content_list;
let content_coming_list;
let localhostName = require('../' + folder._ + '/config.json');
let lang = (getCookie("lang") || "EN");
let content = {};


export default class JoinPageMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            actualArtwork: {},
            artworks: [],
            devoPics: [],
            settings: { _id: "df" },
            userInfo: this.props.userInfo,
            selected_artwork: { interpretations: [], artwork_id: "", title: "" },
            register_email: this.getCookie("register_email"),
            step_register: false,
        };
        lang = (getCookie("lang") || "EN");
        content = page_info_all[lang];
        content_list = contentAll[lang];
        content_coming_list = contentAll_coming[lang];
        this.onChange = this.onChange.bind(this);
        this.showFormButton = this.showFormButton.bind(this);
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    console.log("loged");
                    this.setState({ user: res.data.user, logged: true });
                    this.setState({ step_register: true });
                    if (res.data.user.basic_payment) {
                        window.location.replace("/");
                    };
                } else {
                    this.setState({ logged: false, user: {} });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
        console.log(targetShow);
    }
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    componentDidMount() {
        this.checkLogged();
    }
    proc_register() {
        let data;
        axios.post(localhostName.host + '/api/joinlist/', data)
            .then(res => {
                console.log(res);
                this.setState({ userlists: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <div className={(this.state.step_register ? "" : "join_page ") + + (this.props.blocked ? " blocked" : "")}>
                <div className="join_content">
                    {!this.state.step_register ? <div className="content_boxes">
                        <div className="box box_left">
                            <div className="benefits">
                                {this.props.on_page ? "" : <div className="close_button" onClick={() => { this.changeState("show_filters", false) }}><Link to="/">x</Link></div>}
                                <div className="join_headline">{content.title[lang]}</div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_1[lang]}</strong>
                                    </div>
                                    {content.page_gif ? <img src="/member1.gif" /> : ""}
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_0a[lang]}</strong>{content.benefit_0b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_8a[lang]}</strong>{content.benefit_8b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_content1a[lang]}</strong>{content.benefit_content1b[lang]}
                                        <div className="join_content_list">
                                            <div className="benefit_content_list_t">{content.benefit_content_list_t[lang]}</div>
                                            {content_list.map(c => {
                                                return (<div className="coming_c">
                                                    {c.name}
                                                </div>)
                                            })}
                                        </div>
                                        <div className="join_content_list">
                                            <div className="benefit_content_list_t">{content.benefit_content_list_t2[lang]}</div>
                                            {content_coming_list.map(c => {
                                                return (<div className="coming_c">
                                                    {c.name}
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_2a[lang]}</strong>{content.benefit_2b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_3a[lang]}</strong>{content.benefit_3b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_5a[lang]}</strong>{content.benefit_5b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_6a[lang]}</strong>{content.benefit_6b[lang]}
                                    </div>
                                </div>
                                <div className="benefit">
                                    <div className="desc">
                                        <img className="icon" src="/icons/checked.png" /><strong>{content.benefit_4a[lang]}</strong>{content.benefit_4b[lang]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box box_right">
                            <div className="member_text">{content.membership_t[lang]} <div className="money_back_join">{content.money_back[lang]} </div> <span className="lifetime_more_info" onClick={() => this.showFormButton("membership_faq", true)}>(<i className="fa fa-info-circle" aria-hidden="true"></i>{content.more_i[lang]})</span></div>
                            <div className="next_button">
                                <div id="next_register" onClick={() => { this.showFormButton("step_register", true) }}>Next</div>
                            </div>
                            {/*<input id="register_email" onChange={(e) => this.onChange(e)} value={this.state.register_email} placeholder="Type your email..." />*/}
                        </div>
                    </div> : ""}
                    {this.state.step_register ? 
                    <div className="registerdata_top">
                        <div className="back_link" onClick={() => { this.showFormButton("step_register", false) }}><img className="icon" src="/icons/left-arrow.png" /></div>
                        <div className="close_button" onClick={() => { this.changeState("show_filters", false) }}>
                            <Link to="/">x</Link>
                        </div>
                    </div>  
                        : ""}
                    {this.state.step_register ? <RegisterData /> : ""}
                </div>
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <ModalBoot show={this.state.membership_faq} hide={this.showFormButton} content={"membership_faq"} className="membership_terms_condition">
                    <MembershipFAQ />
                </ModalBoot >
                <ModalBoot show={this.state.membership_tc} hide={this.showFormButton} content={"membership_tc"} className="membership_faq">
                    <MembershipTC />
                </ModalBoot >
            </div>
        )
    }
}    