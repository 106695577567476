import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import ModalBoot from '../../components/Modal.js';
import BucketButtonModal from '../BucketButtonModal.js';
import ItemOverview from '../ItemOverview.js';
import LoadingSVG from '../LoadingSVG.js';
import LoginModal from '../LoginModal.js';
import MembershipFAQ from '../MembershipFAQ.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-tooltip/dist/react-tooltip.css';
import { list_filter } from '../List/functions.js';
import Filter from '../List/Filter_Master.js';
import { setCookie, getCookie, delete_cookie } from '../../functions/functions.js';
import $ from 'jquery';
import parse from 'html-react-parser';
import Article from '../Article.js';
import folder from '../folder.json';
let localhostName = require('../../' + folder._ + '/config.json');
const website_info_all = require('../../' + folder._ + '/Data/website_info.json');
const page_info_all = require('../../' + folder._ + '/Masters/Data/page_info_all.json');
const item_overview_all = require('../../' + folder._ + '/Masters/Data/item_overview_all.json');
const filter_info_all = require('../../' + folder._ + '/Masters/Data/filter_info.json');
const master_path = require('../../' + folder._ + '/Data/master_paths.json');
let filter_info;
let page_data;
let page_info;
let item_overview;
let website_info;
let lang;
let first_m = (getCookie("first_m") || "true");
let master_name;

export default class MasterListMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "de",
            items_list: [],
            items_filter: [],
            userInfo: this.props.userInfo,
            selected_item: {},
            show_filters: false,
            weather_filter: "",
            weather_month: (new Date).getMonth() + 1,
            continent_filter: [],
            pop_filter: "",
            gol_cont_filter: "",
            filter_country: "",
            search_item: "",
            sort_by: "",
            not_rainy: false,
            sunbathing: false,
            mobile_intro: false,
            search_button: false,
            sort_button: false,
            nav_menu: false,
            data_loaded: false,
            show_filter_new: false,
            filter: [],
            filter_values: {},
            slider_filter: {},
            logged: (this.props.logged || false),
            tracker: {
                items: [],
                tabs: [],
                filter: [],
                cities: []
            },
            tracker_daily: {
                items: [],
                tabs: [],
                filter: [],
                cities: []
            },
            items_filters: [],
            tracker_stop: false,
            tracker_stop_daily: false,
            block: false,
            list: [],
            user: (this.props.user ? this.props.user : {}),
            user_data: (this.props.user ? this.props.user.user_data : {}),
            learn_items: [],
            master_name: window.location.pathname.split("/")[2],
            view_style: "Box"
        };
        lang = (getCookie("lang") || "EN");
        master_name = window.location.pathname.split("/")[2];
        if (master_name) {
            page_data = page_info_all[master_name]["page_data"];
            if (lang && lang.length > 0) {
                localhostName.dm = localhostName.dn + lang;
                page_info = page_info_all[master_name][lang];
                filter_info = filter_info_all[master_name][lang];
                item_overview = item_overview_all[master_name][lang];
                website_info = website_info_all[lang];
            } else {
                page_info = page_info_all[master_name]["EN"];
                filter_info = filter_info_all[master_name]["EN"];
                item_overview = item_overview_all[master_name]["EN"];
                website_info = website_info_all["EN"];
            };
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.showFormButtonItem = this.showFormButtonItem.bind(this);
        this.selectCity = this.selectCity.bind(this);
        this.changeState = this.changeState.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setState = this.setState.bind(this);
        this.tracker = this.tracker.bind(this);
        this.updateLearnStatus = this.updateLearnStatus.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }
    notLoggedModal(type, text) {
        if (type === "filter") {
            text = "<span>!</span>You have to be logged in to use this filter."
        };
        if (this.state.logged) {
        } else {
            $(".BucketButtonModal").addClass("show");
            $(".must_logged").html(text);
        };
    }
    tracker(type, value) {
        let tracker = (this.state.tracker || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_daily = (this.state.tracker_daily || {
            items: 0,
            tabs: 0,
            filter: 0,
            cities: 0
        });
        let tracker_stop = this.state.tracker_stop;
        let tracker_stop_daily = this.state.tracker_stop_daily;
        if (type === "item") {
            tracker.items = tracker.items + 1;
            tracker_daily.items = tracker_daily.items + 1;
        };
        if (type === "tab") {
            tracker.tabs = tracker.tabs + 1;
            tracker_daily.tabs = tracker_daily.tabs + 1;
        };
        if (type === "filter") {
            tracker.filter = tracker.filter + 1;
            tracker_daily.filter = tracker_daily.filter + 1;
        };
        this.setState({ tracker, tracker_daily });
        if (tracker.cities > 8 && tracker.tabs > 3 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities > 14 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.items > 10 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities > 30) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.items > 20) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities > 7 && tracker.items > 7 && tracker.tabs > 3) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker.cities > 20 && tracker.filter > 8 && !this.state.logged) {
            this.setState({ tracker_stop: true });
            setCookie("tracker_stop", true, 365);
            this.checkLogged();
        };
        if (tracker_daily.cities > 1 || tracker_daily.items > 1 && tracker_daily.tabs > 1 && !this.state.logged) {
            this.setState({ tracker_stop_daily: true });
            setCookie("tracker_stop_daily", true, 1);
            this.checkLogged();
        };
        setCookie("tracker", JSON.stringify(tracker), 365);
        setCookie("tracker_daily", JSON.stringify(tracker_daily), 1);
    }
    componentDidMount() {
        let url = new URL(window.location.href);
        let selected_item = url.searchParams.get("item");
        let selected_tab = url.searchParams.get("tab");
        let id = url.searchParams.get("id");
        let pay_suc = url.searchParams.get("success");
        if (pay_suc === "basic") {
            this.showFormButton("showPaySuc", true);
        };
        let tracker = getCookie("tracker");
        let tracker_stop = getCookie("tracker_stop");
        let tracker_stop_daily = getCookie("tracker_stop_daily");
        this.setState({ tracker_stop, tracker_stop_daily, tracker: JSON.parse(tracker) });
        this.updateLearnStatus();
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.navbar_box').length) {
                if (!$target.closest('.header_navbar .more').length) {
                    $('.navbar_box').removeClass("show_navbar");
                } else {
                    if ($('.navbar_box.show_navbar').length > 0) {
                        $('.navbar_box').removeClass("show_navbar");
                    } else {
                        $('.navbar_box').addClass("show_navbar");
                    };
                }
            }
        });
        let last_position = $(".page_content").scrollTop();
        $("document2").scroll(function () {
            if ($(".page_content").position().top + 100 > $(window).scrollTop()) {
                $(".items_page_header").removeClass("hide");
                $(".mobile_footer").removeClass("to_top");
            };
            if ($(".page_content").position().top < $(window).scrollTop()) {
                $(".items_page_header").addClass("hide");
                $(".mobile_footer").addClass("to_top");
            };
        });
        axios.get(localhostName.host + '/data_master/' + localhostName.dm + '/' + page_data.data + '/list')
            .then(res => {
                this.setState({ data_loaded: true, list: res.data });
                if (id && id.length > 2) {
                    this.showFormButton("showItemOverview", true, id, selected_tab);
                };
            })
            .catch(function (error) {
                console.log(error);
            });
        this.handlePopState = () => {
            window.location.href = '/';
        };
        window.addEventListener('popstate', this.handlePopState);
        setCookie("content", "/master/discipline", 100);
        if (first_m == "true") {
            $('html, body').scrollTop(0);
            setTimeout(function () {
                $(".overviewtab_info").removeClass("true");
            }, 8000);
            setCookie("first_m", "false", 200);
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user, user_data: this.props.user.user_data })
        }
    }
    showFormButton(targetShow, change, id, tab) {
        if (this.state.logged && !this.props.basic_payment) {
            window.location.replace("/join?basic_payment=not-completed");
            return;
        };
        if (targetShow === "showItemOverview") {
            if (!this.props.blocked || this.props.basic_payment) {
                this.setState({ [targetShow]: change });
            } else {
                window.location.replace("/join");
            };
        } else {
            this.setState({ [targetShow]: change });
        };
        if (targetShow === "showItemOverview" && change === false) {
            window.history.replaceState(null, null, window.location.pathname);
        };
        if (targetShow === "showItemOverview" && change === true) {
            window.history.replaceState(null, null, window.location.pathname + "?id=" + id + (tab ? ("&tab=" + tab) : ""));
        };
    }
    showFormButtonItem(targetShow, change, parent) {
        this.setState({ [targetShow]: change, parent: parent });
    }
    selectCity(item) {
        this.setState({ selected_item: item });
    }
    changeState(state, value) {
        this.setState({ [state]: value });
        if (state === "show_filters" && value == true) {
            if (getCookie("show_filters") != "true") {
                setCookie("show_filters", "true", 365);
            } else {
                this.setState({ show_filter_new: false });
            };
        };
    }
    mobile_intro(value) {
        this.setState({ mobile_intro: value })
    }
    button_click(name, value) {
        this.setState({ [name]: value })
    }
    sort_by(value) {
        this.setState({ sort_by: value });
    }
    select_page(value) {
        window.location.href = value;
    }
    search_item(e) {
        this.setState({ search_item: e.target.value })
    }
    updateLearnStatus() {
        let learn_status;
        let user_data = this.state.user_data;
        if (this.props.logged) {
            learn_status = (user_data.learn_status || {});
        } else {
            learn_status = (JSON.parse(getCookie("learn_status")) || {});
        };
        let item_name = (this.state.master_name ? master_path[this.state.master_name] : localhostName.dn);
        let learn_items = (learn_status[item_name] || []);
        this.setState({ learn_items });
    }
    renderList() {
        let List = (list_filter(this.state) || []);
        function shuffle(array) {
            return array.sort(() => Math.random() - 0.5);
        };
        if (page_info.shuffle_list) {
            shuffle(List);
        };
        let view_style = this.state.view_style;
        let score_rate = function (value) {
            let score_rate = (value / 5 * 100);
            return score_rate;
        };
        let counter = 0;
        let score_color = function (value) {
            let score_rate = (value / 5 * 100);
            if (score_rate > 85) {
                return "#2bde73";
            } else if (score_rate > 65) {
                return "#2bde73";
            } else if (score_rate > 45) {
                return "#ffc924";
            } else if (score_rate > 30) {
                return "#ff4742";
            }
            else {
                return "#ff4742";
            }
        };
        if (List.length < 1) {
            return (
                <h1 className="no_found">No List found.</h1>
            )
        };
        let learn_items = this.state.learn_items;
        if (view_style === "List") {
            let category = [];
            this.state.list.map(c => {
                if (!category.find(d => d.name === c.category)) {
                    let new_c = {
                        "name": c.category,
                        "parents": [{ name: c.parent, topics: [c] }]
                    };
                    category.push(new_c);
                } else {
                    let index = category.findIndex(d => d.name === c.category);
                    let parents = category[index].parents;
                    if (!parents.find(d => d.name === c.parent)) {
                        parents.push({ name: c.parent, topics: [c] });
                        category[index].parents = parents;
                    } else {
                        let index_t = parents.findIndex(d => d.name === c.parent);
                        parents[index_t].topics.push(c);
                        category[index].parents = parents;
                    };
                };
            });
            return (<div className="list_type_items">
                {category.map(c => {
                    return (
                        <div className="cat_box"><div className="cat_">{c.name}</div>
                            {c.parents.map(d => {
                                return (
                                    <div className="parent_box"><div className="par_">{d.name}</div>
                                        {d.topics.map(item => {
                                            counter += 1;
                                            let status = learn_items.find(c => c.id === item.id);
                                            let tabs_r = (status ? status.tabs.filter(c => c !== "Notepad") : []);
                                            let tabs_o = item_overview.tabs;
                                            let completed = Math.round(((tabs_r || []).length / (tabs_o || []).length).toFixed(2) * 100);
                                            completed = (completed >= 100 ? 100 : completed);
                                            return (<div className="topic" data-item={item.name} onClick={() => { this.showFormButton("showItemOverview", true, item.id); this.tracker("item", item.id); this.selectItem(item) }}>{item.single_name} <span className="completed">[{completed}%]</span></div>)
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    );
                })}
            </div>)
        } else {
            return List.map((item) => {
                counter += 1;
                let status = learn_items.find(c => c.id === item.id);
                let tabs_r = (status ? status.tabs.filter(c => c !== "Notepad") : []);
                let tabs_o = item_overview.tabs;
                let completed = Math.round(((tabs_r || []).length / (tabs_o || []).length).toFixed(2) * 100);
                completed = (completed >= 100 ? 100 : completed);
                let error_image = (page_info.default_thumb ? page_info.default_thumb : (`${localhostName.dn}/list_thumbs/${["99d8s602vr7", "7foeweymq2t", "ynd2ms84jw8", "ayoa9nivij", "lv7xlip7ng"][Math.floor(Math.random() * 5)]}.jpg`));
                let thumb_image = {
                    old_own: ('/' + localhostName.dn + '/' + master_name + '/list_thumbs/' + item.id + '.jpg'),
                    own_thumb: (localhostName.data_url + '/' + localhostName.dn + '/thumbs/' + item.id + '.jpg')
                };
                return (
                    <div className={"item_box " + (completed == 100 ? "completed " : " ") + ("show_scores_" + (page_data.show_scores || page_data.show_info))} >
                        <div className="item_box_card" data-item={item.name} onClick={() => { this.showFormButton("showItemOverview", true, item.id); this.tracker("item", item.id); this.selectItem(item) }} >
                            <div className="overlay">
                                <LazyLoadImage
                                    threshold={400}
                                    onError={(e) => $(e.target).attr(`src`, `/${error_image}`)}
                                    src={thumb_image[page_data.thumb_url]} />
                            </div>
                            <div className="overlay"></div>
                            <div className={"top_row " + ("own_top_" + page_data.own_top)}>
                                <div className="rank">{counter}</div>
                                <div className="total_score"><i className="fa fa-cubes"></i> {completed}%</div>
                                {item.own_top ? parse(item.own_top) : ""}
                            </div>
                            <div className="item_location">
                                <div className="item_name">{item.name.includes("<div") ? parse(item.name) : (item.name.length > 140 ? (item.name.slice(0, 140) + " ...") : item.name)}</div>
                            </div>
                            <div className="item_card_bottom">
                                {/*<div className="item_population">
                                    1,5 Mio.
                                </div>*/}
                                {/*<div className="monthly_costs">
                                    {"$" + item["3nights_2person"]} <br /> <span>3 Nights 2 Persons</span>
                                </div>*/}
                            </div>
                            <div className={page_data.show_scores ? "item_scores" : "disable"}>
                                <div className="item_scores_inner">
                                    <div className="item_scores_bars">
                                        {page_info.main_scores.map(s => {
                                            return (
                                                <div className="single_score">
                                                    <p className="score_desc"><img className="image_icon" src={"/icons/" + s.icon} />{s.title}</p>
                                                    <div className="score_bar">
                                                        <div className="score_bar_scale" style={{ width: String(score_rate((item.main_scores || {})[s.name]) + "%"), background: score_color((item.main_scores || {})[s.name]) }}>{(item.main_scores || {})[s.name] > 5 ? 5.00 : (item.main_scores || {})[s.name]}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {page_data.show_info ?<div className= "item_scores thumb_html">
                                <div className="item_scores_inner">
                                   {parse(item.thumb_html)}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                );
            });
        };
    }
    changeSurfPoints(change) {
        let surf_points = this.state.surf_points + Number(change);
        this.setState({ surf_points: surf_points });
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    view_style(style) {
        this.setState({ view_style: style });
    }
    render() {
        let page_count = Number(this.state.list.length) * item_overview.tabs.length;
        let page_c = 0;
        this.state.learn_items.map(c => {
            let tabs_c = c.tabs.filter(c => c !== "Notepad");
            page_c += tabs_c.length;
        });
        let text = {
            "EN": {
                completed: "Completed",
                "many_tabs": "Content",
                "change_tabs": "You can switch between the main content at any time."
            },
            "DE": {
                completed: "Erledigt",
                "many_tabs": "Inhalte",
                "change_tabs": "Sie können jederzeit zwischen den Hauptinhalten wechseln."
            }
        };
        return (
            <div className={"mobile items_page " + (this.state.show_filters ? " show_filters " : "") + master_name}>
                <div className={"items_page_header" + (this.state.show_filters ? " show_filters" : "") + (this.state.nav_menu ? " nav_menu_open" : "")} >
                    <img src={localhostName.data_url + "/banners_mobile/" + page_data.banner} width="400" className="header_video" >
                    </img>
                    <svg viewBox="0 0 1440 120" className="wave" ><path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path></svg>
                    <nav className="navbar navbar_mobile navbar-expand-lg navbar-light bg-light" >
                        <button onClick={() => this.nav_menu()} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link className="navbar-brand" to={website_info.brand_url}><div className="mob_title">{website_info.brand}</div></Link>
                        <div class="nav_tab login">
                            {this.state.logged ?
                                <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                :
                                <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                            }
                        </div>
                        <div className="navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                {website_info.main_content ? <div className="techniques_nav">{website_info.main_nav}</div> : ""}
                                {(website_info.main_content || []).map(p => {
                                    return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition>{p.name}</a>)
                                })}
                                {website_info.main_content ? <hr className="nav_line"></hr> : ""}
                                <div className="techniques_nav">{website_info.more_nav}</div>
                                {(website_info.technique_pages || []).map(p => {
                                    return (<a className="nav-item nav-link" href={p.url} unstable_viewTransition><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</a>)
                                })}
                                <hr className="nav_line"></hr>
                                <Link className="nav-item nav-link" to="/membership">{website_info.nav_names.membership}</Link>
                                <Link className="nav-item nav-link" to="/aboutus">{website_info.nav_names.about_us}</Link>
                                <Link className="nav-item nav-link" to="/contact">{website_info.nav_names.contact}</Link>
                                <Link className="nav-item nav-link" to="/terms-and-conditions">{website_info.nav_names.tc_cs}</Link>
                                <Link className="nav-item nav-link" to="/privacy-policy">{website_info.nav_names.privacy}</Link>
                                <hr className="nav_line"></hr>
                                <div class="nav_tab login">
                                    {this.state.logged ?
                                        <div className="user_log"><Link to={"/profile#user"}><img className="" src="/icons/userA.png" /><span>{this.state.user.firstname} {this.state.user.lastname.charAt(0)}.</span></Link></div>
                                        :
                                        <button onClick={() => this.showFormButton("showLogin", true)}>Login</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="nav_lang">
                        {website_info.languages.length > 1 ? website_info.languages.map(l => {
                            return (
                                <div onClick={() => { setCookie("lang", l.code, 365); window.location.reload(); }}><img src={"/icons/" + l.icon} />{l.name}</div>
                            )
                        }) : ""}
                    </div>
                    <div className="header_title">
                        <div className="title_top">{page_info.frontpage_headline}</div>
                        <div className="title_sub">{page_info.frontpage_masterheadline}</div>
                        <div className="score_pages">
                            <div className="c_t">{text[lang].completed}</div>
                            <div className="outer">
                                <div className="inner" style={{ width: ((page_c / page_count * 100) + "%"), background: "rgb(43, 222, 115)" }}></div>
                                <div className="score_output"><span className="score_number">{page_c}/{page_count} Pages</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Article />
                <div className="page_name_a">
                    <hr class="nav_line" />
                    <div>{website_info.technique_pages.find(c => c.url === window.location.pathname).name}</div>
                </div>
                <div className={"overviewtab_info c_ " + first_m}>
                    <img src="/info2.png" />
                    <div className={lang}>
                        <span>{text[lang].many_tabs}</span><span className="close" onClick={() => $(".overviewtab_info").removeClass("true")}>X</span><br /> {text[lang].change_tabs}
                    </div>
                </div>
                <div className="sort_by select_page">
                    <div className="select_c">Selected Content</div>
                    <select value={window.location.pathname} onChange={(e) => this.select_page(e.target.value)}>
                        {website_info.main_content ? <optgroup label="Main">
                            {(website_info.main_content || []).map(p => {
                                return (<option className="nav-item nav-link" value={p.url} >{p.name}</option>)
                            })}
                        </optgroup> : ""}
                        <optgroup label="Techniques & more - Select">
                            {(website_info.technique_pages || []).map(p => {
                                return (<option className="nav-item nav-link" value={p.url} >{p.name}</option>)
                            })}
                        </optgroup>
                    </select>
                </div>
                <div className={"mobile_footer" + (this.state.show_filters ? " show_filters" : "")} >
                    <div className="box">
                        <div className="filter_button" onClick={() => { this.changeState("show_filters", !this.state.show_filters) }}><i className="fa fa-filter" aria-hidden="true"></i> Filter</div>
                        <div className="sort_button" onClick={() => this.button_click("sort_button", !this.state.sort_button)}>
                            <i className="fa fa-sort" aria-hidden="true"></i> Sort
                            <div className="items_sort_b sort_by">
                                <select value={this.state.sort_by} onChange={(e) => this.sort_by(e.target.value)}>
                                    <optgroup label="Scores">
                                        <option>Sort</option>
                                        {(page_info.sort || []).map(p => {
                                            return (<option value={p.name} >{p.title}</option>)
                                        })}
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="filter_search">
                        <input placeholder={page_info.search_placeholder} onChange={(e) => { this.search_item(e) }} value={this.state.search_item} />
                    </div>
                    {page_data.list_style && this.state.view_style === "Box" ? <div className="change_list_view" onClick={() => this.view_style("List")}><img src="/icons/list_view.png" />Change to List View for better Overview</div> : ""}
                    {page_data.list_style && this.state.view_style === "List" ? <div className="change_list_view" onClick={() => this.view_style("Box")}><img src="/icons/list.png" />Change to Box View</div> : ""}
                    {!this.state.logged ? <div className="more_info_text">To get more information about the {page_info.item_name}, click on the item.</div> : ""}
                </div>
                <div className={"page_content" + (this.state.show_filters ? " show_filters" : "")}>
                    <div className="filter_close" onClick={() => { this.changeState("show_filters", false) }}>x</div>
                    {this.state.show_filter_new ? <div className="show_filter_new_text">Also try one of the many different filters to find the best matching items.</div> : ""}
                    {this.state.show_filters ?
                        <Filter setState={this.setState} filter_info={filter_info} state={this.state} changeState={this.changeState} notLoggedModal={this.notLoggedModal} tracker={this.tracker} />
                        : ""}
                    <Container className={"container_items" + (this.state.show_filters ? " show_filters" : "")} >
                        <div className="">
                            <div className={"items_sorted " + (!this.state.data_loaded ? "data_loading" : "")}>
                                <div className="loading_svg">
                                    <LoadingSVG />
                                </div>
                                {this.renderList()}
                            </div>
                        </div>
                        <ModalBoot show={this.state.showItemOverview} hide={this.showFormButton} content={"showItemOverview"} className="modal_ItemOverview">
                            <ItemOverview key={this.state.list + this.state.user_data + this.state.selected_item} page_data={page_data} localhostName={localhostName} item_overview={item_overview} selected_item={this.state.selected_item} tracker={this.tracker} items_list={this.state.list} updateLearnStatus={this.updateLearnStatus} logged={this.state.logged} user_data={this.state.user_data} refresh={this.props.refresh} />
                        </ModalBoot >
                        <ModalBoot show={this.state.showPaySuc} hide={this.showFormButton} content={"showPaySuc"} className="modal_Login modal_success">
                            <h1>Congratulations!</h1>
                            <div>Your payment has been confirmed, and you are now a Basic Art of War Member for life. This entitles you to full and unrestricted access to all item data, 24 hours a day, 7 days a week.</div>
                            <button class="btn btn-success btn-block float-right" onClick={() => {
                                this.showFormButton("showPaySuc", false);
                                window.history.pushState({}, document.title, "/");
                            }}> Thanks</button>
                        </ModalBoot >
                    </Container>
                </div>
                {this.state.search_button ? <div className="filter_search">
                    <input placeholder="Search City" onChange={(e) => { this.search_item(e) }} value={this.state.search_item} />
                </div> : ""}
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal checkLogged={this.checkLogged} hide={this.showFormButton} />
                </ModalBoot >
                <BucketButtonModal checkLogged={this.checkLogged} hide={this.showFormButton} />
            </div>
        )
    }
}    