import React, { Component } from 'react';
import axios from 'axios';
import ModalBoot from '../../components/Modal';
import $ from 'jquery';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import folder from '../../System/folder.json';
let localhostName = require('../config.json');
const getAllItemObjects = (inputObject) => {
    const result = [];
    for (const key in inputObject) {
        if (inputObject.hasOwnProperty(key)) {
            const items = inputObject[key];
            items.forEach(item => {
                if (item.id) {
                    result.push({
                        key: key,
                        id: item.id,
                        tabs: item.tabs
                    });
                }
            });
        }
    }
    return result;
};

export default class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.item_name,
            buddies: [],
            user_data: (this.props.user.user_data || { infos: {} }),
            user: (this.props.user || {}),
            items_over: (this.props.items_over || []),
            new_items: [],
            new_countries: [],
            item_country: "",
            items_select: [],
            new_bucket_items: [],
            list:[],
            homeitem: {},
            homeitem_array: [],
            homeitem_letters: "",
            photo_upload_error: ""
        };
        this.showFormButton = this.showFormButton.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeHomeitem = this.onChangeHomeitem.bind(this);
    }
    componentDidMount() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data, homeitem: (user_data.infos.homeitem || {}) });
                console.log(res.data.user_data);
            })
            .catch(function (error) {
                console.log(error);
            });
        $(document).ready(function () {
            var element = $(".tabs");
            $(".scroll_right").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition + 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".top_bar").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".top_bar").removeClass("hide_left");
                };
            });
            $(".scroll_left").on("click", function () {
                var currentPosition = element.scrollLeft();
                var newPosition = currentPosition - 100;
                element.animate({
                    scrollLeft: newPosition
                }, 500);
                if (newPosition <= 0) {
                    $(".top_bar").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".top_bar").removeClass("hide_right");
                };
            });
            $(".tabs").scroll(function () {
                var newPosition = element.scrollLeft();
                if ((newPosition + element.width()) >= element.get(0).scrollWidth) {
                    $(".top_bar").addClass("hide_right");
                };
                if (newPosition > 0) {
                    $(".top_bar").removeClass("hide_left");
                };
                if (newPosition <= 0) {
                    $(".top_bar").addClass("hide_left");
                };
                if ((newPosition + element.width()) < element.get(0).scrollWidth) {
                    $(".top_bar").removeClass("hide_right");
                };
            });
        });
        let list_all = [];
        list_all = list_all.concat((this.state.user.user_data || {}).favourites);
        let learn_status = getAllItemObjects((this.state.user.user_data || {}).learn_status);
        list_all = list_all.concat(learn_status);
        axios.post(localhostName.host + '/data_master/' + localhostName.dm + '/list_all/', {list_all})
            .then(res => {
                console.log(res.data);
                this.setState({ list: res.data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user, user_data: this.props.user.user_data })
        }
    }
    reload() {
        axios.get(localhostName.host + '/api/users/userdata', { withCredentials: true })
            .then(res => {
                let user_data = res.data.user_data;
                user_data.infos = (user_data.infos || { infos: {} })
                this.setState({ user_data: user_data });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onChange(e) {
        this.setState({ [e.target.id]: e.target.value });
        if ([e.target.id] == "item_country") {
            console.log(e.target.value);
            console.log(this.state.items_over);
            let items_select = this.state.items_over.filter((c) => c.country === e.target.value);
            this.setState({ items_select: items_select });
            console.log(items_select);
        };
    }
    onChangeInfo(e) {
        let user_data = this.state.user_data;
        user_data.infos = (user_data.infos || {});
        user_data.infos[e.target.id] = e.target.value;
        console.log(user_data);
        this.setState({ user_data: user_data });
    }
    onChangeHomeitem(e) {
        let user_data = this.state.user_data;
        user_data.infos = (user_data.infos || {});
        user_data.infos[e.target.id] = e.target.value;
        this.setState({ homeitem: { name: e.target.value } });
        let item_letters = { item_letters: [e.target.value.masterstring(0, 2)] };
        if (e.target.value.length > 1 && e.target.value.toLowerCase().masterstring(0, 2) !== this.state.homeitem_letters.masterstring(0, 2)) {
            axios.get(localhostName.host + '/Data/items_geo/' + e.target.value.masterstring(0, 2))
                .then(res => {
                    this.setState({ homeitem_array: res.data, homeitem_letters: e.target.value.toLowerCase().masterstring(0, 2) });
                    $(".list_compare_items").css("display", "inherit");
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            this.setState({ homeitem_letters: e.target.value.toLowerCase() });
        };
    }
    saveHomeitem(item) {
        let homeitem = { homeitem: item };
        axios.post(localhostName.host + '/api/users/homeitem', homeitem, { withCredentials: true })
            .then(res => {
                this.reload();
                this.showFormButton("showAddInfo", false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    addCountry() {
        let new_countries = { countries: this.state.new_countries };
        console.log(new_countries);
        if (this.state.new_countries.length > 0) {
            axios.post(localhostName.host + '/api/users/addcountry', new_countries, { withCredentials: true })
                .then(res => {
                    console.log(res.data);
                    this.reload();
                })
                .catch(function (error) {
                    console.log(error);
                });
        };
    }
    addCity() {
        let new_items = { items: this.state.new_items };
        axios.post(localhostName.host + '/api/users/additem', new_items, { withCredentials: true })
            .then(res => {
                this.reload();
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    addBucketCity() {
        let new_items = { items: this.state.new_bucket_items };
        console.log(this.state.new_bucket_items);
        axios.post(localhostName.host + '/api/users/addbucketitem', new_items, { withCredentials: true })
            .then(res => {
                this.reload();
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    saveInfo() {
        let infos = { infos: this.state.user_data["infos"] };
        axios.post(localhostName.host + '/api/users/saveinfos', infos, { withCredentials: true })
            .then(res => {
                this.reload();
                this.showFormButton("showAddInfo", false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    change_add_country(e) {
        let new_countries = this.state.new_countries;
        if (e.target.checked) {
            new_countries.push(e.target.value);
        } else if (!e.target.checked) {
            let index = new_countries.findIndex((c) => c === e.target.value);
            new_countries.splice(index, 1);
        };
        this.setState({ new_countries: new_countries });
    }
    change_add_item(e) {
        let new_items = this.state.new_items;
        if (e.target.checked) {
            new_items.push(JSON.parse(e.target.value));
        } else if (!e.target.checked) {
            let index = new_items.findIndex((c) => c === JSON.parse(e.target.value));
            new_items.splice(index, 1);
        };
        this.setState({ new_items: new_items });
    }
    change_add_bucket_item(e) {
        let new_items = this.state.new_bucket_items;
        if (e.target.checked) {
            new_items.push(JSON.parse(e.target.value));
        } else if (!e.target.checked) {
            let index = new_items.findIndex((c) => c === JSON.parse(e.target.value));
            new_items.splice(index, 1);
        };
        console.log(new_items);
        this.setState({ new_bucket_items: new_items });
    }
    handleFileChange = (e) => {
        this.setState({ photo_upload_error: "" });
        const reader = new FileReader();
        if (e.target.files[0].size > 350000) {
            this.setState({ photo_upload_error: "Image too big. Maximal size is 350kb." });
            return;
        }
        reader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                const imageWidth = image.width;
                const imageHeight = image.height;
                console.log('Image Dimensions:', imageWidth, 'x', imageHeight, 'pixels');
                if (imageWidth < 200 || imageHeight < 200) {
                    console.log("tooB");
                    this.setState({ photo_upload_error: "Image too small. Minimal size is 200px x 200px." });
                    return;
                };
                this.setState({ setPreviewImage: reader.result });
            };
        };
        reader.readAsDataURL(e.target.files[0]);
        this.setState({ selectedFile: e.target.files[0] });
    }
    handleUpload() {
        const { selectedFile } = this.state;
        console.log(selectedFile);
        const formData = new FormData();
        formData.append('photo', selectedFile, selectedFile.name);
        axios.post(localhostName.host + '/api/users/uploadprofilephoto', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(res => {
            if (res.data.message === "Photo upload successful.") {
                window.location.replace("/profile");
            };
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        let user_data = this.state.user_data;
        let user = this.state.user;
        function calculateAge(birthday) {
            const ageDifMs = Date.now() - new Date(birthday).getTime();
            const ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };
        let list_n = {};
        console.log(this.state.list);
        this.state.list.map(l => {
            list_n[l.id] = l;
        });
        return (
            <div className="user_profile">
                <div className="profile_header">
                    <div className="container profile_name_info">
                        <div className="profile_photo" onClick={() => { this.showFormButton("showPhotoUpload", true); }}>
                            <i className="fas fa-edit" onClick={() => { this.showFormButton("showPhotoUpload", true); }}></i>
                            <img src={(user_data.profile_photo || "").length > 0 ? ("https://golftus.s3.eu-west-2.amazonaws.com/" + user_data.profile_photo) : "/profile_avatar.png"} />
                        </div>
                        <div className="profile_name">
                            <div>{user.firstname} {user.lastname}</div>
                        </div>
                    </div>
                </div>
                <div className="top_bar hide_left">
                    <div className="tabs">
                        <div className="selected">Profile</div>
                    </div>
                </div>
                <div className="container">
                    <div className="profile_profile">
                        <div className="played_and_bucket">
                            <div className="main_title">Favourites</div>
                            <div className="box">
                                <div className="countries_list">
                                    {user_data.favourites ?
                                        user_data.favourites.map((c) => {
                                            return (
                                                <div className="item_l">{(list_n[c.id] || {}).name}</div>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="played_and_bucket">
                            <div className="main_title">Completed</div>
                            <div className="box">
                                <div className="countries_list">
                                    {user_data.favourites ?
                                        user_data.favourites.map((c) => {
                                            return (
                                                <div className="item_l">{(list_n[c.id] || {}).name}</div>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalBoot show={this.state.showPhotoUpload} hide={this.showPhotoUpload} content={"showPhotoUpload"} className="modal_userprofile upload_photo_modal club_mail">
                    <div className="send_clubmail">
                        <div className="clubmail_header">
                            <div className="text">Profile Photo Upload</div>
                            <div className="close" onClick={() => { this.showFormButton("showPhotoUpload", false); }}>X</div>
                        </div>
                        {this.state.setPreviewImage ? <img className="previewImage" src={this.state.setPreviewImage} /> : ""}
                        {this.state.photo_upload_error.length > 0 ? <p className="login_error">!Login failed. Wrong email or password.</p> : ""}
                        <div className="textbox">
                            <input type="file" onChange={(e) => this.handleFileChange(e)} />
                        </div>
                        <div className="clubmail_footer">
                            <div className="cancel">Cancel</div>
                            <div className="send" onClick={() => this.handleUpload()}>Upload</div>
                        </div>
                    </div>
                </ModalBoot >
            </div>
        )
    }
}    