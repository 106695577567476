import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import PageHeaderDesktop from './PageHeaderDesktop.js';
import PageHeaderMobile from './PageHeaderMobile.js';
import { getCookie } from '../functions/functions.js';
import folder from './folder.json';
const contentAll = require('../' + folder._ + '/Data/content.json');
let content;
let lang;

export default class MobileStart extends Component {
  constructor() {
    super();
    this.state = {
    };
    lang = getCookie("lang");
    if (lang && lang.length > 0) {
      content = contentAll[lang];
    } else {
      content = contentAll["EN"];
    };
  }
  render() {
    let text = {
      sc: {
        "EN": "Select Content",
        "DE": "Inhalt auswählen"
      },
      txt: {
        "EN": "Unlock your potential as a business leader with our cutting-edge platform, meticulously crafted to empower you in nurturing your business's growth and development. Pick a topic and freely switch between them at any time.",
        "DE": "Entfalten Sie Ihr Potenzial als Eltern mit unserer innovativen Plattform, die sorgfältig entwickelt wurde, um Sie bei der Förderung des Wachstums und der Entwicklung Ihres Kindes zu unterstützen. Wählen Sie ein Thema und wechseln Sie jederzeit frei zwischen ihnen."
      }
    }
    return (
      <div className="mobile mobile_start">
        {isMobile ?
          <PageHeaderMobile key={this.props.logged} logged={this.props.logged} user={this.props.user} />
          :
          <PageHeaderDesktop key={this.props.logged} logged={this.props.logged} user={this.props.user} />
        }
        <div className="pageContent">
          <div className="flexContent">
            <div className="start_select_c">{text.sc[lang]}</div>
            <div className="start_select_t"><div>{text.txt[lang]}</div></div>
            <div className="content_list">
              {content.map(c => {
                return (
                  <Link to={c.url} className="main_c">
                    <img src={c.image} />
                    <div className="overlay"></div>
                    <div className="c_text">
                      <div>{c.name}</div>
                      <div className="desc">{c.desc}</div>
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

