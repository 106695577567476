import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Footer from '../../System/Footer.js';
import UserProfile from './UserProfile.js';
import ClubMail from './ClubMail.js';
import Collection from './Collection.js';
import axios from 'axios';
import LoginModal from '../../System/LoginModal.js';
import ModalBoot from '../../components/Modal.js';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import $ from 'jquery';
import { getCookie } from '../../functions/functions.js';
import folder from '../../System/folder.json';
let localhostName = require('../config.json');
const website_info_all = require('../Data/website_info.json');
const page_info_all = require('../Data/page_info_all.json');
let website_info;
let page_info;
let lang = (getCookie("lang") || "EN");

export default class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "profile",
            buddies: [],
            user: this.props.user,
            items_over: [],
            showLogin: false
        };
        if (lang && lang.length > 0) {
            localhostName.dm = localhostName.dn + lang;
            page_info = page_info_all[lang];
            website_info = website_info_all[lang];
        } else {
            page_info = page_info_all["EN"];
            website_info = website_info_all["EN"];
        };
        this.showFormButton = this.showFormButton.bind(this);
    }
    checkLogged() {
        axios.get(localhostName.host + '/api/users/logged', { withCredentials: true })
            .then(res => {
                if (res.data.status) {
                    console.log(res.data);
                    this.setState({ user: res.data.user, logged: true });
                    console.log("er");
                } else {
                    console.log("pi");
                    this.setState({ logged: false, user: {} });
                    this.showFormButton("showLogin", true);
                }
            })
            .catch(function (error) {
                window.location.replace("/");
                console.log(error);
            });
    }
    componentDidMount() {
        this.checkLogged();
        if (this.props.logged && !this.props.basic_payment) {
            window.location.replace("/join?basic_payment=not-completed");
            return;
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({ user: this.props.user })
        }
    }
    showFormButton(targetShow, change) {
        this.setState({ [targetShow]: change });
    }
    changePage(value) {
        this.setState({ page: value })
    }
    logout() {
        axios.get(localhostName.host + '/api/users/logout', { withCredentials: true })
            .then(res => {
                window.location.replace("/");
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    nav_menu() {
        this.setState({ nav_menu: !this.state.nav_menu });
    }
    render() {
        return (
            <div className="non_go_profile">
                {isMobile ?
                    <header className="App-header">
                        <nav className={"navbar navbar-profile " + (this.state.nav_menu ? " nav_menu_open" : "")}>
                            <Link className="nav_a" to="/" >
                                <img src="/icons/cityscape.png" />
                                <div>{lang = "DE" ? "Techniken" : "Techniques"}</div>
                            </Link>
                            <Link className="nav_a" to="/profile" onClick={() => { this.changePage("profile") }}>
                                <img src="/icons/user.png" />
                                <div>{lang = "DE" ? "Profil" : "Profile"}</div>
                            </Link>
                            <div className="nav_a" onClick={() => { this.logout() }}>
                                <img src="/icons/logout.png" />
                                <div>Logout</div>
                            </div>
                            <div className="nav_a" onClick={() => this.nav_menu()}>
                                <img src="/icons/menu.png" />
                                <div>Menu</div>
                            </div>
                            <div className="navbar-collapse" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    <Link className="nav-item nav-link" to="/membership">Membership</Link>
                                    <Link className="nav-item nav-link" to="/aboutus">About us</Link>
                                    <Link className="nav-item nav-link" to="/contact">Contact</Link>
                                    <Link className="nav-item nav-link" to="/terms-and-conditions">Ts&Cs</Link>
                                    <Link className="nav-item nav-link" to="/privacy-policy">Privacy & Cookies</Link>
                                </div>
                            </div>
                        </nav>
                    </header>
                    :
                    <header className="App-header desktop desktop_extern_header">
                        <nav className="navbar navbar-profile header_navbar links_navbar">
                            <Link to={website_info.brand_url}><div className="logo_name">{website_info.brand}</div></Link>
                            {/*<a className="nav_a" href="#mail" onClick={()=>{this.changePage("club_mail")}}>
                            <div>ClubMail</div>
                        </a>*/}
                            {/*<a className="nav_a" href="#collection" onClick={()=>{this.changePage("collection")}}>
                            <div>Collection</div>
                        </a>*/}
                            <a className="nav_a" href="/profile" onClick={() => { this.changePage("profile") }}>
                                <div>Profile</div>
                            </a>
                            <a className="nav_a" href="#logout" onClick={() => { this.logout() }}>
                                <div>Logout</div>
                            </a>
                            <div className="links_navbar">
                                <a class="dropdown">
                                    <div className="nav_tab">
                                        {website_info.more_nav}
                                    </div>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <div className="techniques">
                                            <div className=" techniques_nav">{website_info.more_nav}</div>
                                            {(website_info.technique_pages || []).map(p => {
                                                return (<div class="dropdown-item" onClick={() => { window.location.href = p.url; }} ><img className="nav_icon" src={"/icons/" + p.icon} />{p.name}</div>)
                                            })}
                                        </div>
                                        <div>
                                            <Link class="dropdown-item" to="/aboutus"><img className="nav_icon" src="/icons/group.png" />{page_info.nav_names.about_us}</Link>
                                            <Link class="dropdown-item" to="/contact"><img className="nav_icon" src="/icons/letter.png" />{page_info.nav_names.contact}</Link>
                                            <Link class="dropdown-item" to="/terms-and-conditions"><img className="nav_icon" src="/icons/terms-and-conditions.png" />{page_info.nav_names.tc_cs}</Link>
                                            <Link class="dropdown-item" to="/privacy-policy"><img className="nav_icon" src="/icons/privacy-policy.png" />{page_info.nav_names.privacy}</Link>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </nav>
                    </header>
                }
                <ModalBoot show={this.state.showLogin} hide={this.showFormButton} content={"showLogin"} className="modal_Login">
                    <LoginModal hide={this.showFormButton} checkLogged={this.checkLogged} />
                </ModalBoot >
                <div key={this.state.user}>
                    {this.state.page === "profile" && this.state.user._id ? <UserProfile status={"self"} items_over={this.state.items_over} user={this.state.user} key={this.state.user + this.state.items_over} /> : ""}
                </div>
                {this.state.page === "club_mail" ? <ClubMail /> : ""}
                {this.state.page === "collection" ? <Collection /> : ""}
                <Footer />
            </div>
        )
    }
}    