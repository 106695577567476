import React, { Component } from 'react';
import { Tooltip } from 'react-tooltip';
import RangeSlider from '../../UI/RangeSlider';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";
import filter_text from '../filter_text.json';

export default class Filter extends Component {
    componentDidMount() {
        console.log(this.props.state);
    }
    onChange(e) {
        this.props.setState({ [e.target.id]: e.target.value });
    }
    changeState(state, value) {
        this.props.setState({ [state]: value });
    }
    update_filter(value, mustlog) {
        if (mustlog) {
            this.props.notLoggedModal("filter");
            return;
        };
        let filter = this.props.state.filter;
        let index = filter.findIndex((f) => f === value);
        if (index < 0) {
            filter.push(value);
        } else {
            filter = filter.filter(e => e !== value);
        };
        this.props.setState({ filter: filter });
    }
    slider_filter(name, value, mustlog) {
        if (mustlog) {
            this.props.notLoggedModal("filter");
            return;
        };
        let slider_filter = this.props.state.slider_filter;
        slider_filter[name] = value;
        this.props.setState({ slider_filter: slider_filter });
    }
    weather_filter(value) {
        if (this.props.state.weather_filter === value) {
            this.props.setState({ weather_filter: "" });
        } else {
            this.props.setState({ weather_filter: value });
        };
    }
    weather_month(value) {
        this.props.setState({ weather_month: value });
    }
    continent_filter(value) {
        let continent_filter = this.props.state.continent_filter;
        let index = continent_filter.findIndex((f) => f === value);
        if (index < 0) {
            continent_filter.push(value);
        } else {
            continent_filter = continent_filter.filter(e => e !== value);
        };
        this.props.setState({ continent_filter: continent_filter });
    }
    pop_filter(value) {
        let pop_filter = this.props.state.pop_filter;
        if (pop_filter == value) {
            pop_filter = "";
        } else {
            pop_filter = value;
        };
        this.props.setState({ pop_filter: pop_filter });
    }
    gol_cont_filter(value) {
        let gol_cont_filter = this.props.state.gol_cont_filter;
        if (gol_cont_filter == value) {
            gol_cont_filter = "";
        } else {
            gol_cont_filter = value;
        };
        this.props.setState({ gol_cont_filter: gol_cont_filter });
    }
    render() {
        let countries = {
            UK: "United Kingdom", DE: "Germany", ES: "Spain", PT: "Portugal", FR: "France", IE: "Ireland", IT: "Italy", TR: "Turkey", NL: "Netherlands",
            US: "USA", MX: "Mexico", CA: "Canada", AU: "Australia", NZ: "New Zealand", JP: "Japan", CR: "Costa Rica", DO: "Dominican Republic", TH: "Thailand", CO: "Colombia", KR: "South Korea", VN: "Vietnam", BR: "Brazil", AR: "Argentina", CH: "Switzerland", AT: "Austria", ZA: "South Africa", JM: "Jamaica", IS: "Iceland", SE: "Sweden", TW: "Taiwan", NO: "Norway", IN: "New Delhi", BS: "Bahamas", KE: "Kenya", MU: "Mauritius", PHL: "Philippines", PE: "Peru", MY: "Malaysia", AE: "United Arab Emirates", CN: "China", BE: "Belgium", HU: "Hungary", GR: "Greece", GI: "Gibraltar", CZ: "Czech Republic", DK: "Denmark", HK: "Hong Kong", SG: "Singapore", ID: "Indonesia", NP: "Nepal", QA: "Qatar", MT: "Malta", MC: "Monaco", KY: "Cayman Islands", CL: "Chile", NA: "Namibia", GH: "Ghana"
        };
        let countries_names = {};
        Object.keys(countries).map((key, index) => {
            countries_names[countries[key]] = key;
        });
        countries_names = Object.keys(countries_names).sort().reduce(
            (obj, key) => {
                obj[key] = countries_names[key];
                return obj;
            },
            {}
        );
        return (
            <div className="filter_navbox">
                <div className="navbar_subtitle main_title">
                    {"Filters"}
                    <i className="nav_line"></i>
                </div>
                <div className="box" onClick={(e) => {this.props.tracker("filter", e.target.innerText); this.props.visit_tracker("filter", e.target.innerText);}}>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Weather
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row three_bound">
                            <div className={this.props.state.weather_filter === "cold" ? "selected" : ""} onClick={() => { this.weather_filter("cold") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["cold"]}>🍦 Cold</div>
                            <div className={this.props.state.weather_filter === "mild" ? "selected" : ""} onClick={() => { this.weather_filter("mild") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["mild"]}>🌤 Mild</div>
                            <div className={this.props.state.weather_filter === "warm" ? "selected" : ""} onClick={() => { this.weather_filter("warm") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["warm"]}>☀️ Warm</div>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.not_rainy ? "selected" : ""} onClick={() => { this.changeState("not_rainy", !this.props.state.not_rainy) }}><img className="image_icon" src="/icons/rain.png" />Not Rainy</div>
                            <div className={this.props.state.sunbathing ? "selected" : ""} onClick={() => { this.changeState("sunbathing", !this.props.state.sunbathing) }}><img className="image_icon" src="/icons/sunbathing.png" />Sunbathing</div>
                        </div>
                        <div className="sort_by">
                            <span>In</span>
                            <select value={this.props.state.weather_month} onChange={(e) => this.weather_month(e.target.value)}>
                                <optgroup label="Month">
                                    <option value='1'>Janaury</option>
                                    <option value='2'>February</option>
                                    <option value='3'>March</option>
                                    <option value='4'>April</option>
                                    <option value='5'>May</option>
                                    <option value='6'>June</option>
                                    <option value='7'>July</option>
                                    <option value='8'>August</option>
                                    <option value='9'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                </optgroup>
                            </select>
                        </div>
                        <div className="navbar_subtitle">
                            Environment
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("beach") ? "selected" : ""} onClick={() => { this.update_filter("beach") }}><img className="image_icon" src="/icons/parasol.png" />Beach</div>
                            <div className={this.props.state.filter.includes("mountains") ? "selected" : ""} onClick={() => { this.update_filter("mountains") }}><img className="image_icon" src="/icons/mountain.png" />Mountains</div>
                            <div className={this.props.state.filter.includes("green") ? "selected" : ""} onClick={() => { this.update_filter("green") }}><img className="image_icon" src="/icons/grassland.png" />Green</div>
                            <div className={this.props.state.filter.includes("big_lake") ? "selected" : ""} onClick={() => { this.update_filter("big_lake") }}><img className="image_icon" src="/icons/lake.png" />Big Lake</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Where
                            <i className="nav_line"></i>
                        </div>
                        <div className="sort_by">
                            <span>Country</span>
                            <select id="filter_country" value={this.props.state.filter_country} onChange={(e) => this.onChange(e)}>
                                <optgroup label="Country">
                                    <option value="">All</option>
                                    <option value="AU">Australia</option>
                                    <option value="CA">Canada</option>
                                    <option value="DE">Germany</option>
                                    <option value="IE">Ireland</option>
                                    <option value="JP">Japan</option>
                                    <option value="UK">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="">---</option>
                                    {Object.keys(countries_names).map((key, index) => {
                                        return (
                                            <option value={countries_names[key]}>{key}</option>
                                        )
                                    })}
                                </optgroup>
                            </select>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("countries_not_played") ? "selected" : ""} onClick={() => { this.update_filter("countries_not_played", true) }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["countries_not_played"]}><img className="image_icon" src="/icons/coronavirus.png" />Countries you didn't play</div>
                            <div className={this.props.state.filter.includes("cities_not_played") ? "selected" : ""} onClick={() => { this.update_filter("cities_not_played", true) }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["cities_not_played"]}><img className="image_icon" src="/icons/location-pin.png" />Cities you didn't play</div>
                        </div>
                        <div className="navbar_row  betaND" data-tooltip-id={"filter"} data-tooltip-html={filter_text["distance_homecity"]}>
                            <RangeSlider onChange={(e) => this.slider_filter("distance_homecity", e, true)} min={10} max={3000} step={0.2} gap={0.2} label="Distance to your Home City (km)" name="distance_homecity" width="90%" />
                        </div>
                        <div className="navbar_row six_bound">
                            <div className={this.props.state.continent_filter.includes("NA") ? "selected" : ""} onClick={() => { this.continent_filter("NA") }}><img className="image_icon" src="/icons/asia.png" />Northern & Central America</div>
                            <div className={this.props.state.continent_filter.includes("SA") ? "selected" : ""} onClick={() => { this.continent_filter("SA") }}><img className="image_icon" src="/icons/south-america.png" />South America</div>
                            <div className={this.props.state.continent_filter.includes("EUR") ? "selected" : ""} onClick={() => { this.continent_filter("EUR") }}><img className="image_icon" src="/icons/europe.png" />Europe</div>
                            <div className={this.props.state.continent_filter.includes("AF") ? "selected" : ""} onClick={() => { this.continent_filter("AF") }}><img className="image_icon" src="/icons/location.png" />Africa</div>
                            <div className={this.props.state.continent_filter.includes("ME") ? "selected" : ""} onClick={() => { this.continent_filter("ME") }}><img className="image_icon" src="/icons/middle-east.png" />Middle East</div>
                            <div className={this.props.state.continent_filter.includes("AS") ? "selected" : ""} onClick={() => { this.continent_filter("AS") }}><img className="image_icon" src="/icons/asia.png" />Asia & Oceania</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Golf
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("golf_total", e)} min={0} max={5} step={0.2} gap={0.2} label="Golf Total Score" name="golf_total" width="90%" />
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("golf_quantity") ? "selected" : ""} onClick={() => { this.update_filter("golf_quantity") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_quantity"]}><img className="image_icon" src="/icons/golf.png" />Many Golf Courses</div>
                            <div className={this.props.state.filter.includes("golf_quality") ? "selected" : ""} onClick={() => { this.update_filter("golf_quality") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_quality"]}><img className="image_icon" src="/icons/quality.png" />High Quality of Courses</div>
                            <div className={this.props.state.filter.includes("old_courses") ? "selected" : ""} onClick={() => { this.update_filter("old_courses") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["old_courses"]}><img className="image_icon" src="/icons/history.png" />Old Courses</div>
                            <div className={this.props.state.filter.includes("ocean_courses") ? "selected" : ""} onClick={() => { this.update_filter("ocean_courses") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["ocean_courses"]}><img className="image_icon" src="/icons/wave.png" />Ocean View Courses</div>
                            <div className={this.props.state.filter.includes("mountain_courses") ? "selected" : ""} onClick={() => { this.update_filter("mountain_courses") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["mountain_courses"]}><img className="image_icon" src="/icons/mountain2.png" />Mountain View Courses</div>
                            {/*<div className={this.props.state.filter.includes("desert_courses") ? "selected" : ""} onClick={() => { this.update_filter("desert_courses") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["desert_courses"]}><img className="image_icon" src="/icons/desert2.png" />Desert Courses</div>*/}
                            <div className={this.props.state.filter.includes("low_tee_price") ? "selected" : ""} onClick={() => { this.update_filter("low_tee_price") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["low_tee_price"]}><img className="image_icon" src="/icons/reduce-cost.png" />Cheap Tee Time</div>
                            <div className={this.props.state.filter.includes("many_golf_events") ? "selected" : ""} onClick={() => { this.update_filter("many_golf_events") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["many_golf_events"]}><img className="image_icon" src="/icons/golf-event.png" />Many Golf Events</div>
                            <div className={this.props.state.filter.includes("many_golf_resorts") ? "selected" : ""} onClick={() => { this.update_filter("many_golf_resorts") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["many_golf_resorts"]}><img className="image_icon" src="/icons/resort.png" />Many Golf Resorts</div>
                            <div className={this.props.state.filter.includes("topgolf") ? "selected" : ""} onClick={() => { this.update_filter("topgolf") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["topgolf"]}><img className="image_icon" src="/icons/resort.png" />Topgolf Complex</div>
                            <div className={this.props.state.gol_cont_filter == "golf_world_100" ? "selected" : ""} onClick={() => { this.gol_cont_filter("golf_world_100") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_world_100"]}><img className="image_icon" src="/icons/earth.png" />World Top 100 Courses</div>
                            <div className={this.props.state.gol_cont_filter == "golf_europe_100" ? "selected" : ""} onClick={() => { this.gol_cont_filter("golf_europe_100") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_europe_100"]}><img className="image_icon" src="/icons/europe.png" />Europe Top 100 Courses</div>
                            <div className={this.props.state.gol_cont_filter == "golf_asia_100" ? "selected" : ""} onClick={() => { this.gol_cont_filter("golf_asia_100") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_asia_100"]}><img className="image_icon" src="/icons/asia.png" />Asia Top 100 Courses</div>
                            <div className={this.props.state.gol_cont_filter == "golf_north_america_100" ? "selected" : ""} onClick={() => { this.gol_cont_filter("golf_north_america_100") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["golf_north_america_100"]}><img className="image_icon" src="/icons/asia.png" />North America Top 100 Courses</div>
                            <div className={this.props.state.filter.includes == "pga_championship" ? "selected" : ""} onClick={() => { this.gol_cont_filter("pga_championship") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["pga_championship"]}><img className="image_icon" src="/icons/championship.png" />PGA Championship</div>
                            <div className={this.props.state.filter.includes == "open_championship" ? "selected" : ""} onClick={() => { this.gol_cont_filter("open_championship") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["open_championship"]}><img className="image_icon" src="/icons/tournament.png" />The Open Championship</div>
                            <div className={this.props.state.filter.includes == "us_open" ? "selected" : ""} onClick={() => { this.gol_cont_filter("us_open") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["us_open"]}><img className="image_icon" src="/icons/tournament3.png" />U.S. Open</div>
                            <div className={this.props.state.filter.includes == "ryder_cup" ? "selected" : ""} onClick={() => { this.gol_cont_filter("ryder_cup") }} data-tooltip-id={"filter"} data-tooltip-html={filter_text["ryder_cup"]}><img className="image_icon" src="/icons/tournament2.png" />Ryder Cup</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Cost
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("3nights_2person", e)} min={300} max={2000} step={0.2} gap={0.2} label="Avg. Total Cost for 3 nights for 2 person US $" name="nights_2person" width="90%" />
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("hotel_night", e)} min={30} max={350} step={0.2} gap={0.2} label="Hotel Night Price (avg.) US $" name="hotel_night" width="90%" />
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("cheapest_teetime", e)} min={30} max={120} step={0.2} gap={0.2} label="Cheapest Tee Times Price (avg.) US $" name="cheapest_teetime" width="90%" />
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("beer_price", e)} min={1} max={15} step={0.2} gap={0.2} label="Beer Price (avg.) US $" name="beer_price" width="90%" />
                        </div>
                        <div className="navbar_row unbound betaND">
                            <div>💃 Cheap Hotels</div>
                            <div>⛱ Cheap Tee Time</div>
                            <div>🙂 Cheap Nightlife</div>
                            <div>🌈 Cheap Flights</div>
                            <div>🌈 Cheap Rentals</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Activities
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("skiing") ? "selected" : ""} onClick={() => { this.update_filter("skiing") }}><img className="image_icon" src="/icons/ski.png" />Ski</div>
                            <div className={this.props.state.filter.includes("jet_ski") ? "selected" : ""} onClick={() => { this.update_filter("jet_ski") }}><img className="image_icon" src="/icons/jet-ski.png" />Jet Ski</div>
                            <div className={this.props.state.filter.includes("sailing") ? "selected" : ""} onClick={() => { this.update_filter("sailing") }}><img className="image_icon" src="/icons/sailing.png" />Sailing</div>
                            <div className={this.props.state.filter.includes("hiking") ? "selected" : ""} onClick={() => { this.update_filter("hiking") }}><img className="image_icon" src="/icons/hiking.png" />Hiking</div>
                            <div className={this.props.state.filter.includes("rock_climbing") ? "selected" : ""} onClick={() => { this.update_filter("rock_climbing") }}><img className="image_icon" src="/icons/climb.png" />Rock Climbing</div>
                            <div className={this.props.state.filter.includes("fishing") ? "selected" : ""} onClick={() => { this.update_filter("fishing") }}><img className="image_icon" src="/icons/fishing.png" />Fishing</div>
                            <div className={this.props.state.filter.includes("surfing") ? "selected" : ""} onClick={() => { this.update_filter("surfing") }}><img className="image_icon" src="/icons/surfing.png" />Surfing</div>
                            <div className={this.props.state.filter.includes("canoeing") ? "selected" : ""} onClick={() => { this.update_filter("canoeing") }}><img className="image_icon" src="/icons/safety.png" />Canoeing</div>
                            <div className={this.props.state.filter.includes("paragliding") ? "selected" : ""} onClick={() => { this.update_filter("paragliding") }}><img className="image_icon" src="/icons/parag.png" />Paragliding</div>
                            <div className={this.props.state.filter.includes("bungee_jumping") ? "selected" : ""} onClick={() => { this.update_filter("bungee_jumping") }}><img className="image_icon" src="/icons/bungee.png" />Bungee Jumping</div>
                            <div className={this.props.state.filter.includes("scuba_diving") ? "selected" : ""} onClick={() => { this.update_filter("scuba_diving") }}><img className="image_icon" src="/icons/diving.png" />Scuba diving</div>
                            <div className={this.props.state.filter.includes("horseback_riding") ? "selected" : ""} onClick={() => { this.update_filter("horseback_riding") }}><img className="image_icon" src="/icons/horse.png" />Horseback riding</div>
                            <div className={this.props.state.filter.includes("kitesurfing") ? "selected" : ""} onClick={() => { this.update_filter("kitesurfing") }}><img className="image_icon" src="/icons/kite.png" />Kitesurfing</div>
                            <div className={this.props.state.filter.includes("off-road_racing") ? "selected" : ""} onClick={() => { this.update_filter("off-road_racing") }} ><img className="image_icon" src="/icons/racing.png" />Off-road racing</div>
                            <div className={this.props.state.filter.includes("safari") ? "selected" : ""} onClick={() => { this.update_filter("safari") }}><img className="image_icon" src="/icons/safari.png" />Safari</div>
                            <div className={this.props.state.filter.includes("hot_air_ballon") ? "selected" : ""} onClick={() => { this.update_filter("hot_air_ballon") }}><img className="image_icon" src="/icons/baloon.png" />Hot Air Balloon</div>
                            <div className={this.props.state.filter.includes("desert_walking") ? "selected" : ""} onClick={() => { this.update_filter("desert_walking") }}><img className="image_icon" src="/icons/desert.png" />Desert Walking</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Population Size
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row five_bound">
                            <div className={this.props.state.pop_filter == "pop_1" ? "selected" : ""} onClick={() => { this.pop_filter("pop_1") }}><img className="image_icon" src="/icons/village.png" />{"< 50.000"}</div>
                            <div className={this.props.state.pop_filter == "pop_2" ? "selected" : ""} onClick={() => { this.pop_filter("pop_2") }}><img className="image_icon" src="/icons/houses.png" />50.000 - 200.000</div>
                            <div className={this.props.state.pop_filter == "pop_3" ? "selected" : ""} onClick={() => { this.pop_filter("pop_3") }}><img className="image_icon" src="/icons/city2.png" />200.000 - 1 Mio.</div>
                            <div className={this.props.state.pop_filter == "pop_4" ? "selected" : ""} onClick={() => { this.pop_filter("pop_4") }}><img className="image_icon" src="/icons/architecture.png" />1 Mio. - 3 Mio.</div>
                            <div className={this.props.state.pop_filter == "pop_5" ? "selected" : ""} onClick={() => { this.pop_filter("pop_5") }}><img className="image_icon" src="/icons/skyscraper.png" />{"> 3 Mio."}</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            How
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("high_safety") ? "selected" : ""} onClick={() => { this.update_filter("high_safety") }}><img className="image_icon" src="/icons/safety.png" />Very safe</div>
                            <div className={this.props.state.filter.includes("quality_life") ? "selected" : ""} onClick={() => { this.update_filter("quality_life") }}><img className="image_icon" src="/icons/quality-of-life.png" />High Quality of Life</div>
                            <div className={this.props.state.filter.includes("english_speaking") ? "selected" : ""} onClick={() => { this.update_filter("english_speaking") }}><img className="image_icon" src="/icons/englishspeaking.png" />English speaking</div>
                            <div className={this.props.state.filter.includes("good_nightlife") ? "selected" : ""} onClick={() => { this.update_filter("good_nightlife") }}><img className="image_icon" src="/icons/drink.png" />Good nightlife</div>
                            <div className={this.props.state.filter.includes("wealthy") ? "selected" : ""} onClick={() => { this.update_filter("wealthy") }}><img className="image_icon" src="/icons/gold-bars.png" />Wealthy</div>
                            <div className={this.props.state.filter.includes("many_events") ? "selected" : ""} onClick={() => { this.update_filter("many_events") }}><img className="image_icon" src="/icons/events.png" />Many events</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Sightseeing
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row">
                            <RangeSlider onChange={(e) => this.slider_filter("sightseeing", e)} min={0} max={5} step={0.2} gap={0.2} label="Sightseeing Total Score" name="sightseeing" width="90%" />
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("historic") ? "selected" : ""} onClick={() => { this.update_filter("historic") }}><img className="image_icon" src="/icons/historic.png" />Historic</div>
                            <div className={this.props.state.filter.includes("many_museums") ? "selected" : ""} onClick={() => { this.update_filter("many_museums") }}><img className="image_icon" src="/icons/many_museums.png" />Museums</div>
                            <div className={this.props.state.filter.includes("many_art_galleries") ? "selected" : ""} onClick={() => { this.update_filter("many_art_galleries") }}><img className="image_icon" src="/icons/many_art_galleries.png" />Art Galleries</div>
                            <div className={this.props.state.filter.includes("natural_wonder") ? "selected" : ""} onClick={() => { this.update_filter("natural_wonder") }}><img className="image_icon" src="/icons/natural_wonder.png" />Natural Wonder</div>
                            <div className={this.props.state.filter.includes("events") ? "selected" : ""} onClick={() => { this.update_filter("events") }}><img className="image_icon" src="/icons/events.png" />Events</div>
                            <div className={this.props.state.filter.includes("many_skyscrapers") ? "selected" : ""} onClick={() => { this.update_filter("many_skyscrapers") }}><img className="image_icon" src="/icons/many_skyscrapers.png" />Skyscrapers</div>
                            <div className={this.props.state.filter.includes("historic_architecture") ? "selected" : ""} onClick={() => { this.update_filter("historic_architecture") }}><img className="image_icon" src="/icons/historic_architecture.png" />Historic Architecture / Houses</div>
                            <div className={this.props.state.filter.includes("unesco") ? "selected" : ""} onClick={() => { this.update_filter("unesco") }}><img className="image_icon" src="/icons/unesco.png" />Unesco</div>
                            <div className={this.props.state.filter.includes("old_churches") ? "selected" : ""} onClick={() => { this.update_filter("old_churches") }}><img className="image_icon" src="/icons/old_churches.png" />Old Churches</div>
                            <div className={this.props.state.filter.includes("mosques") ? "selected" : ""} onClick={() => { this.update_filter("mosques") }}><img className="image_icon" src="/icons/mosque.png" />Mosques</div>
                            <div className={this.props.state.filter.includes("buddhist_temples") ? "selected" : ""} onClick={() => { this.update_filter("buddhist_temples") }}><img className="image_icon" src="/icons/buddhist.png" />Buddhist Temples</div>
                            <div className={this.props.state.filter.includes("many_statues") ? "selected" : ""} onClick={() => { this.update_filter("many_statues") }}><img className="image_icon" src="/icons/statue.png" />Many Statues</div>
                            <div className={this.props.state.filter.includes("many_tourists") ? "selected" : ""} onClick={() => { this.update_filter("many_tourists") }}><img className="image_icon" src="/icons/traveler.png" />Many Tourists</div>
                            <div className={this.props.state.filter.includes("roman_city") ? "selected" : ""} onClick={() => { this.update_filter("roman_city") }}><img className="image_icon" src="/icons/roman_city.png" />Roman City</div>
                            <div className={this.props.state.filter.includes("castles") ? "selected" : ""} onClick={() => { this.update_filter("castles") }}><img className="image_icon" src="/icons/castles.png" />Castles</div>
                            <div className={this.props.state.filter.includes("exotic_animals") ? "selected" : ""} onClick={() => { this.update_filter("exotic_animals") }}><img className="image_icon" src="/icons/monkey.png" />Exotic Animals</div>
                            <div className={this.props.state.filter.includes("high_mountain") ? "selected" : ""} onClick={() => { this.update_filter("high_mountain") }}><img className="image_icon" src="/icons/mountain.png" />{"High Mountain (>3000 m)"}</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Food
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>🙂 Unesco Food City</div>
                            <div>🙂 Street Food</div>
                            <div>💃 Michelin 5 Stars Restaurants</div>
                            <div>⛱ Food Safety</div>
                            <div>🙂 Local Fruits & Vegetables</div>
                            <div>🙂 Meat Quality</div>
                            <div>🙂 For Cheese Lovers</div>
                            <div>🙂 For Meat Lovers</div>
                            <div>🙂 Vegan Friendly</div>
                            <div>🙂 Dessert Lovers</div>
                            <div>🙂 Pizza Lovers</div>
                            <div>🙂 Sushi Lovers</div>
                            <div>🙂 Kebab Lovers</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Infrastructure & Transport
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("metro_system") ? "selected" : ""} onClick={() => { this.update_filter("metro_system") }}><img className="image_icon" src="/icons/train.png" />Large Metro Network</div>
                            <div className={this.props.state.filter.includes("publictransport") ? "selected" : ""} onClick={() => { this.update_filter("publictransport") }}><img className="image_icon" src="/icons/publictransport.png" /> Great Public Transport</div>
                            <div className={this.props.state.filter.includes("airport") ? "selected" : ""} onClick={() => { this.update_filter("airport") }}><img className="image_icon" src="/icons/airport.png" /> Big Airport</div>
                            <div className={this.props.state.filter.includes("walkability") ? "selected" : ""} onClick={() => { this.update_filter("walkability") }}><img className="image_icon" src="/icons/walk2.png" /> Walkable</div>
                            <div className={this.props.state.filter.includes("traffic_safety") ? "selected" : ""} onClick={() => { this.update_filter("traffic_safety") }}><img className="image_icon" src="/icons/traffic.png" /> Traffic Safety</div>
                            <div className={this.props.state.filter.includes("taxi") ? "selected" : ""} onClick={() => { this.update_filter("taxi") }}><img className="image_icon" src="/icons/taxi.png" /> Many Taxis</div>
                            <div className={this.props.state.filter.includes("bike") ? "selected" : ""} onClick={() => { this.update_filter("bike") }}><img className="image_icon" src="/icons/bicycle.png" /> Bike Friendly</div>
                            <div className={this.props.state.filter.includes("car_rent") ? "selected" : ""} onClick={() => { this.update_filter("car_rent") }}><img className="image_icon" src="/icons/car-rent.png" /> Cheap Car Rentals</div>
                            <div className={this.props.state.filter.includes("speed_limit") ? "selected" : ""} onClick={() => { this.update_filter("speed_limit") }}><img className="image_icon" src="/icons/road.png" /> Highway No Speed Limit</div>
                            <div className={this.props.state.filter.includes("highspeed_train") ? "selected" : ""} onClick={() => { this.update_filter("highspeed_train") }}><img className="image_icon" src="/icons/train1.png" /> High Speed Trains</div>
                            <div className={this.props.state.filter.includes("uber") ? "betaND selected" : "betaND"} onClick={() => { this.update_filter("uber") }}><img className="image_icon" src="/icons/uber.png" /> Uber</div>
                        </div>
                    </div>
                    <div className="subbox">
                        <div className="navbar_subtitle">
                            Events
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div className={this.props.state.filter.includes("concert") ? "selected" : ""} onClick={() => { this.update_filter("concert") }}><img className="image_icon" src="/icons/concert.png" />Many Concerts</div>
                            <div className={this.props.state.filter.includes("formula1") ? "selected" : ""} onClick={() => { this.update_filter("formula1") }}><img className="image_icon" src="/icons/formula.png" />Formula 1</div>
                            <div className={this.props.state.filter.includes("soccer") ? "selected" : ""} onClick={() => { this.update_filter("soccer") }}><img className="image_icon" src="/icons/919420.png" />Great Football (Soccer) Team</div>
                            <div className={this.props.state.filter.includes("basketball") ? "selected" : ""} onClick={() => { this.update_filter("basketball") }}><img className="image_icon" src="/icons/2112088.png" />Great Basketball Team</div>
                            <div className={this.props.state.filter.includes("nfl") ? "selected" : ""} onClick={() => { this.update_filter("nfl") }}><img className="image_icon" src="/icons/10665807.png" />Great NFL Team</div>
                            <div className={this.props.state.filter.includes("tennis") ? "selected" : ""} onClick={() => { this.update_filter("tennis") }}><img className="image_icon" src="/icons/3136314.png" />Tennis Tourn.</div>
                            <div className={this.props.state.filter.includes("musical") ? "selected" : ""} onClick={() => { this.update_filter("musical") }}><img className="image_icon" src="/icons/2410365.png" />Musical & Ballet</div>
                            <div className={this.props.state.filter.includes("fashion_show") ? "selected" : ""} onClick={() => { this.update_filter("fashion_show") }}><img className="image_icon" src="/icons/5044774.png" />Fashion Show</div>
                            <div className={this.props.state.filter.includes("horse_race") ? "selected" : ""} onClick={() => { this.update_filter("horse_race") }}><img className="image_icon" src="/icons/3205117.png" />Horse Races</div>
                            <div className={this.props.state.filter.includes("comedy_shows") ? "selected" : ""} onClick={() => { this.update_filter("comedy_shows") }}><img className="image_icon" src="/icons/10531493.png" />Comedy Shows</div>
                            <div className={this.props.state.filter.includes("film_festivals") ? "selected" : ""} onClick={() => { this.update_filter("film_festivals") }}><img className="image_icon" src="/icons/4221874.png" />Film festival</div>
                            <div className={this.props.state.filter.includes("carnival") ? "selected" : ""} onClick={() => { this.update_filter("carnival") }}><img className="image_icon" src="/icons/2858795.png" />Carnival</div>
                            <div className={this.props.state.filter.includes("beer_festival") ? "selected" : ""} onClick={() => { this.update_filter("beer_festival") }}><img className="image_icon" src="/icons/beerfest.png" />Beerfest</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Health
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>💃 Clean Air</div>
                            <div>🙂 Great Hospitals</div>
                            <div>⛱ No smoking</div>
                            <div>💃 Not overweight</div>
                            <div>🙂 Food Safety</div>
                            <div>⛱ Jogging friendly</div>
                            <div>⛱ Great Dentists</div>
                            <div>⛱ Drinkable Tapwater</div>
                            <div>⛱ Not Noisy</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Beauty & Wellness
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>💃 Many Spas</div>
                            <div>🙂 Beauty Parlors</div>
                            <div>⛱ Sauna culture</div>
                            <div>💃 Many Hamams</div>
                            <div>🙂 Acupuncture</div>
                            <div>⛱ Many Massage Parlors</div>
                            <div>⛱ Diet Coaches</div>
                            <div>⛱ Fashion City</div>
                            <div>⛱ Luxury Fashion Shops</div>
                            <div>⛱ Fashion Events</div>
                            <div>⛱ Cheap Hairtransplantation</div>
                            <div>⛱ Cheap plastic surgery</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Kids
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>🙂 Disney Land</div>
                            <div>🙂 Freiz</div>
                            <div>⛱ Food Safety</div>
                            <div>🙂 Local Fruits & Vegetables</div>
                            <div>🙂 Safety</div>
                            <div>🙂 Many Parks</div>
                            <div>🙂 Zoo</div>
                            <div>🙂 Dessert Lovers</div>
                            <div>🙂 Pizza Lovers</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Education
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>🙂 Highly educated</div>
                            <div>💃 Nature Index (Top 50)</div>
                            <div>⛱ World Top 100 Unis</div>
                            <div>🙂 Nobel prize winner</div>
                            <div>🙂 PISA Test</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Development
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row three_bound">
                            <div>💃 Developed</div>
                            <div>🙂 Developing</div>
                            <div>⛱ Underdeveloped</div>
                        </div>
                        <div className="navbar_row unbound">
                            <div>💃 Freedom of speech</div>
                            <div>⛱ Democratic</div>
                            <div>🙂 Highly educated</div>
                            <div>🌈 Wealthy</div>
                            <div>🌈 High life expectancy</div>
                            <div>🌈 Speaks English</div>
                            <div>🌈 Great Infrastructure</div>
                            <div>🌈 High Salaries</div>
                            <div>🌈 Gender Equality</div>
                            <div>🌈 Legal weed</div>
                            <div>🌈 Legal abortion</div>
                        </div>
                    </div>
                    <div className="subbox betaND">
                        <div className="navbar_subtitle">
                            Economy
                            <i className="nav_line"></i>
                        </div>
                        <div className="navbar_row unbound">
                            <div>💃 High GDP Capita</div>
                            <div>⛱ Low unemployment</div>
                            <div>🙂 Innovative</div>
                            <div>🙂 Easy doing business</div>
                            <div>🙂 Many Millionaires</div>
                            <div>🙂 Finance Centre (Top 30)</div>
                            <div>🙂 Tech Hub</div>
                            <div>🙂 Stock Market</div>
                        </div>
                    </div>
                    <div className="results_button" onClick={() => { this.props.changeState("show_filters", false) }}>
                        Show results
                    </div>
                </div>
                <Tooltip id="filter" className="filter_tooltip" />
            </div>
        )
    }
}    